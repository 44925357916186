<template>
  <div class="base-skill-root-container">
    <span class="base-skill-title">技能列表</span>
    <div class="base-skill-tag-container">
      <div v-for="(item,index) in dataSource.tags" :key="index" class="base-skill-tag">
        <Icon type="md-pricetag" />
        <span style="margin-left:5px;margin-right:15px">{{item}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
      projectId: 0,
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
  },
  methods: {
    ...mapActions({
    }),
  },
};
</script>

<style scoped>
.base-skill-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 40px;
  background-color: #fff;
  border-radius: 14px;
}
.base-skill-title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 30px;
}
.base-skill-tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.base-skill-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #f4f4f4;
}
</style>