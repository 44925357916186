<template>
  <div class="project-base-root-container">
    <div
      class="project-base-image"
      :style="{
        'background-image':
          'url(' +
          (baseInfo.img_url ? baseInfo.img_url : icons.employerAvatar) +
          ')',
      }"
    ></div>
    <span class="project-base-name" @click="jumpCompanyDetail"
      >项目所有者：{{ baseInfo.name }}</span
    >
    <div class="project-base-desc-container">
      暂无描述
      <!-- {{ baseInfo.desc }} -->
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as icon from "../../../common/icon";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
      baseInfo: {
        company_id: 0,
        img_url: "",
        name: "",
        desc: "",
      },
    };
  },
  methods: {
    ...mapActions({
      projectGetCompanyBaseAction: "projectGetCompanyBase",
    }),
    refresh() {
      this.projectGetCompanyBaseAction({
        project_id: this.dataSource.project_id,
      })
        .then((res) => {
          this.baseInfo = res[0];
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    jumpCompanyDetail() {
      this.$router.push({
        name: "company-detail",
        params: {
          id: this.baseInfo.company_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.project-base-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  padding-bottom: 20px;
  max-width: 350px;
  background-color: #fff;
  border-radius: 14px;
}
.project-base-image {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  border: 1px solid #eeeeee;
}
.project-base-name {
  font-size: 12px;
  font-weight: 500;
  margin-top: 30px;
  color: #2c2e30;
  cursor: pointer;
}
.project-base-desc-container {
  border-radius: 8px;
  background-color: #f9f8e1;
  padding: 20px 15px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 30px;
  width: 100%;
}
</style>